@import '../../../../../../../../../styles/index';
.error {
  display: flex;
  color: $rosso-color;
  font-size: 12px;
  line-height: 1.2;
}
.modal__upload-image {
  width: 400px;
  display: flex;
  flex-direction: column;
	padding-top: 20px;

  .uploaded-image {
    max-height: 500px;
    height: auto;
    width: 100%;
    object-fit: contain;
    display: block;
    margin: 20px 0;
  }

	.upload-img__btn {
		background-color: $violet-color;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;
		position: relative;
		cursor: pointer;
		width: 100%;
		border-radius: 10px;
		height: 50px;
		margin-bottom: 15px;

		span {
			color: $white-color;
			font-weight: $font-weight-bolder;
			margin-left: 10px;
		}

		.btn__label {
			cursor: pointer;
			display: flex;
			align-items: center;

			img {
				display: block;
				height: 100%;
			}
		}
	}
}
